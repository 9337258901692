<template>
  <Layout>
    <div class="row mb-5">
      <div class="col-md-6 mt-5 registration-left">
        <div class="row">
          <div class="col-md-4 offset-md-2">
            <img src="@/assets/images/man-1.png" alt="Man" height="203" width="190">
          </div>
          <div class="col-md-6">
            <p class="font-title">
              {{$t("paycheck_protection_program")}}
            </p>
            <p class="font-sub-title">
              {{$t("loan_company_lookup_search_tool")}}
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-md-10 offset-md-2">
            <p class="font-text mt-4">
              {{$t("the_ppp_epidemic")}}
            </p>

            <p class="mt-5 text-free-registration mb-0">
              {{$t("easy_and_hassle_free_registration")}}
            </p>
            <p class="join-now pt-0">
              {{$t("join_now")}}
            </p>
          </div>
        </div>
      </div>

      <div class="col-md-6 registration-right pb-4">
        <div class="row">
          <div class="col-md-10">
            <div class="registration-form">
              <p class="welcome">{{$t("welcome")}}</p>
              <p class="create-your-account">{{$t("create_your_account_with_us")}}</p>
              <img class="middle-line-logo" src="@/assets/images/middle_line_logo.png" alt="Loan"/>

              <div style="padding-left: 6%; padding-right: 6%">



                <form class="needs-validation" @submit.prevent="tryToRegisterIn">
                  <b-alert v-model="registerSuccess" class="mt-3" variant="success" dismissible>Registration successfully.</b-alert>
                  <b-alert v-model="isRegisterError" class="mt-3" variant="danger" dismissible>{{ regError }}</b-alert>
                  <div v-if="notification.message" :class="'alert ' + notification.type">{{ notification.message }}</div>

                  <div>
                    <label for="username" class="form-label">{{$t("username")}}</label>
                    <div class="form-icon right">
                      <input type="text" class="form-control form-control-icon" id="username"
                             v-model="user.username"
                             :class="{'is-invalid': submitted && v$.user.username.$error,}"
                             :placeholder="$t('type_in')">
                      <i class="ri-user-3-line red-color"></i>
                    </div>
                    <div v-for="(item, index) in v$.user.username.$errors" :key="index" class="red-color">
                      <span v-if="item.$message">{{ item.$message }}</span>
                    </div>
                  </div>

                  <div class="mt-4">
                    <label for="email" class="form-label">{{$t("email")}}</label>
                    <div class="form-icon right">
                      <input type="email" class="form-control form-control-icon" id="email"
                             v-model="user.email"
                             :class="{'is-invalid': submitted && v$.user.email.$error,}"
                             :placeholder="$t('type_in')">
                      <i class="ri-mail-line red-color"></i>
                    </div>
                    <div v-for="(item, index) in v$.user.email.$errors" :key="index" class="red-color">
                      <span v-if="item.$message">{{ item.$message }}</span>
                    </div>
                  </div>

                  <div class="mt-4" v-if="this.isSeenPassword" >
                    <label for="password_eye" class="form-label">{{$t("password")}}</label>
                    <div class="form-icon right">
                      <input v-model="user.password" type="text" class="form-control form-control-icon" id="password_eye"
                             :class="{'is-invalid': submitted && v$.user.password.$error,}"
                             :placeholder="$t('type_in')">
                      <i @click="OnClickedPasswordVisibilityChange" class="ri-eye-line red-color cursor-pointer"></i>
                    </div>
                    <div v-for="(item, index) in v$.user.password.$errors" :key="index" class="red-color">
                      <span v-if="item.$message">{{ item.$message }}</span>
                    </div>
                  </div>
                  <div class="mt-4" v-else>
                    <label for="password_close_eye" class="form-label">{{$t("password")}}</label>
                    <div class="form-icon right">
                      <input v-model="user.password" v-if="!this.isSeenPassword" type="password" class="form-control form-control-icon" id="password_close_eye"
                             :class="{'is-invalid': submitted && v$.user.password.$error,}"
                             :placeholder="$t('type_in')">
                      <i @click="OnClickedPasswordVisibilityChange" class="ri-eye-off-line red-color cursor-pointer"></i>
                    </div>
                    <div v-for="(item, index) in v$.user.password.$errors" :key="index" class="red-color">
                      <span v-if="item.$message">{{ item.$message }}</span>
                    </div>
                  </div>


                  <div class="mt-4" v-if="this.isSeenPassword" >
                    <label for="password_confirm_eye" class="form-label">{{$t("confirm_password")}}</label>
                    <div class="form-icon right">
                      <input v-model="user.confirm_password" type="text" class="form-control form-control-icon" id="password_confirm_eye"
                             :class="{'is-invalid': submitted && v$.user.confirm_password.$error,}"
                             :placeholder="$t('type_in')">
                      <i @click="OnClickedPasswordVisibilityChange" class="ri-eye-line red-color cursor-pointer"></i>
                    </div>
                    <div v-for="(item, index) in v$.user.confirm_password.$errors" :key="index" class="red-color">
                      <span v-if="item.$message">{{ item.$message }}</span>
                    </div>
                  </div>
                  <div class="mt-4" v-else>
                    <label for="password_confirm_close_eye" class="form-label">{{$t("confirm_password")}}</label>
                    <div class="form-icon right">
                      <input v-model="user.confirm_password" v-if="!this.isSeenPassword" type="password" class="form-control form-control-icon" id="password_confirm_close_eye"
                             :class="{'is-invalid': submitted && v$.user.confirm_password.$error,}"
                             :placeholder="$t('type_in')">
                      <i @click="OnClickedPasswordVisibilityChange" class="ri-eye-off-line red-color cursor-pointer"></i>
                    </div>
                    <div v-for="(item, index) in v$.user.confirm_password.$errors" :key="index" class="red-color">
                      <span v-if="item.$message">{{ item.$message }}</span>
                    </div>
                  </div>

                  <div class="mt-3 d-grid gap-2">
                    <button class="btn submit" type="submit">{{$t("sign_up")}}</button>
                  </div>
                </form>

                <div class="d-flex justify-content-between link-sign-in text-center mt-3">
                  <RouterLink to="/registration">
                    <span class="red-color">{{$t("sign_in_with_sso")}}</span>
                  </RouterLink>

                  <RouterLink to="/login">
                    <span class="existing-user text-black">{{$t("existing_user")}} </span>
                    <span class="red-color">{{$t("sign_in")}}</span>
                  </RouterLink>
                </div>
              </div>

              <PrivacyTerms class="mt-4"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/layouts/main";
import PrivacyTerms from "@/components/privacy-terms";
import {
  required,
  email,
  helpers
} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import appConfig from "../../../app.config.json";

import {
  authMethods,
} from "@/state/helpers";

export default {
  page: {
    title: "Signup",
    meta: [{
      name: "description",
      content: appConfig.description,
    }, ],
  },
  name: "signup",
  components: {
    Layout,
    PrivacyTerms
  },
  setup(){
    return {
      v$: useVuelidate()
    }
  },
  data() {
    return {
      isSeenPassword: false,
      user: {
        username: "",
        email: "",
        password: "",
        confirm_password: "",
      },
      submitted: false,
      regError: null,
      tryingToRegister: false,
      isRegisterError: false,
      registerSuccess: false,
    };
  },
  validations() {
    return{
      user: {
        username: {
          required: helpers.withMessage("Username is required", required),
        },
        email: {
          required: helpers.withMessage("Email is required", required),
          email: helpers.withMessage("Please enter valid email", email),
        },
        password: {
          required: helpers.withMessage("Password is required", required),
        },
        confirm_password: {
          required: helpers.withMessage("Confirm Password is required", required),
        },
      },
    }
  },
  mounted() {
    document.documentElement.setAttribute("data-layout", "horizontal");
    document.documentElement.setAttribute("data-layout-width", "fluid");
    document.documentElement.setAttribute("data-topbar", "dark");
    document.documentElement.setAttribute("data-sidebar-size", "sm");
    document.documentElement.setAttribute("data-layout-position", "fixed"); //scrollable
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },
  methods: {
    ...authMethods,
    OnClickedPasswordVisibilityChange(){
      this.isSeenPassword = !this.isSeenPassword;
    },
    async tryToRegisterIn() {
      this.submitted = true;
      // stop here if form is invalid
      this.v$.$touch();

      if (this.v$.$invalid) {
        return;
      }else {
        this.tryingToRegister = true;
        // Reset the regError if it existed.
        this.regError = null;
        this.isRegisterError = false;

        this.$isLoading(true); // show loading screen
        await this.register(this.user)
        .then((response) => {
          const {status, message} = response;
          if (status === "success"){
            this.tryingToRegister = false;
            this.isRegisterError = false;
            this.registerSuccess = true;
            // if (this.registerSuccess) {
            //   this.$router.push(
            //       this.$route.query.redirectFrom || {
            //         name: "home",
            //       }
            //   );
            // }
          }else {
            this.regError = message;
            this.tryingToRegister = false;
            this.isRegisterError = true;
          }
        })
        .catch((error) => {
          this.tryingToRegister = false;
          this.regError = error ? error : "";
          this.isRegisterError = true;
        })
        .finally(() => {
          this.$isLoading(false);
        })
      }
    },
  }
}
</script>

<style scoped>

</style>